import { defineStore } from 'pinia';

export const usePlatformStore = defineStore({
  id: 'platform',
  state: () => ({
    drawer: false,
    loading: false
  }),
  actions: {
    drawerPosition(position: boolean) {
      this.drawer = position;
    },
    isLoading(loader: boolean) {
      this.loading = loader;
    }
  }
});
