<script setup lang="ts">
import SocialLink from './SocialLink.vue';
import LogoWithNoText from '../assets/images/PlusTime-IT-Logo-White-No-Text.webp';
import { useDisplay } from 'vuetify';
import { useRouter, RouteRecord } from 'vue-router';
import { computed, ref } from 'vue';

const router = useRouter();
const { mobile } = useDisplay();
const navItems = computed<RouteRecord[]>(() =>
  router.getRoutes().filter((route) => route.meta.showBottomNav)
);

const showForm = ref<boolean>(true);
function formSuccessful() {
  showForm.value = false;
}
</script>

<template>
  <v-footer
    dark
    class="footer pt-4"
    :class="{ footer: !mobile, 'footer-mobile': mobile }"
  >
    <v-container fluid class="container">
      <v-row no-gutters>
        <v-col class="pt-16" cols="12" md="6">
          <v-row no-gutters align="center" justify="center">
            <v-col
              class="text-center text-md-right pb-10 pb-md-0"
              cols="12"
              md="6"
            >
              <v-lazy
                :options="{ threshold: 0.5 }"
                transition="fade-transition"
              >
                <v-img
                  alt="plustime-logo-no-text"
                  class="mx-auto ml-md-auto"
                  max-height="200"
                  max-width="200"
                  :src="LogoWithNoText"
                />
              </v-lazy>
            </v-col>
            <v-col class="text-center text-md-left" cols="12" md="6">
              Our mission is to provide a superior and professional service of
              the highest quality, uniquely tailored for each customer.
              Upholding principles of honesty and strong work ethics, we strive
              to exceed expectations, ensuring unparalleled satisfaction in
              every interaction.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pt-10 pb-12">
          <v-row no-gutters>
            <v-col cols="12" class="mb-xs-10">
              <span class="text-h5 text-white font-weight-bold"
                >Connect With Us</span
              >
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-16 mb-md-0">
            <v-col cols="12">
              <SocialLink
                name="PlusTime IT Github Link"
                class="d-inline-block pa-2"
                :icon="'mdi-github'"
                link="https://github.com/PlusTimeIT"
                tooltip="Check out of GitHub"
              />
              <SocialLink
                name="PlusTime IT Facebook Link"
                class="d-inline-block pa-2"
                :icon="'mdi-facebook'"
                link="https://www.facebook.com/plustimeit/"
                tooltip="Check out of Facebook"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="mt-n16">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="mx-auto mt-n16">
              <v-lazy
                :options="{ threshold: 0.5 }"
                transition="fade-transition"
              >
                <FormLoader
                  v-show="showForm"
                  class="global-forms"
                  name="Guest\Subscribe"
                  @successful="formSuccessful"
                />
              </v-lazy>
              <v-alert
                v-show="!showForm"
                type="success"
                icon="mdi-robot-happy-outline"
                class="mt-16"
              >
                You have successfully subscribed.
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <ul>
            <li
              v-for="(nav, index) in navItems"
              class="d-inline-flex"
              :key="index"
            >
              <RouterLink
                class="text-white text-decoration-none text-caption pa-4"
                :to="{ name: nav.name }"
                >{{ nav.meta.title }}
              </RouterLink>
              <div class="py-3">|</div>
            </li>
            <li class="d-inline-flex px-3">
              <span class="text-white text-caption"
                >© 2024 PlusTime IT - K M GRILLS PTY LTD</span
              >
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">
@import '../styles/variables';

.footer {
  position: relative;
  clip-path: $polygon-4;
  background: $footer-gradient-1;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: $footer-border-1;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: $polygon-4;
    pointer-events: none;
    background: $radial-gradient-1;
  }

  &-mobile {
    clip-path: $polygon-4-mobile !important;

    &::before {
      clip-path: $polygon-4-mobile !important;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }
}
</style>
