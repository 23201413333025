<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { usePlatformStore } from '../plugins/pinia/stores/platform';
import { storeToRefs } from 'pinia';
import { useRouter, RouteRecord } from 'vue-router';
import WhiteLogoNoText from '../assets/images/PlusTime-IT-Logo-White-No-Text.webp';
import WhiteLogo from '../assets/images/PlusTime-IT-Logo-White.webp';

const { smAndUp, mdAndUp } = useDisplay();
const router = useRouter();

const { drawer } = storeToRefs(usePlatformStore());
const currentNav = ref(0);
const navItems = computed<RouteRecord[]>(() =>
  router.getRoutes().filter((route) => route.meta.showTopNav)
);
</script>

<template>
  <div>
    <v-navigation-drawer
      v-if="!smAndUp"
      v-model="drawer"
      class="navbar top-layer"
      temporary
      disable-resize-watcher
    >
      <v-list-item aria-label="Plus Time IT Logo">
        <router-link to="/" tag="span">
          <v-img
            alt="plustime-logo"
            class="mx-auto mt-10 mb-5"
            max-height="150"
            max-width="150"
            :src="WhiteLogo"
            :to="'/home'"
            :style="{ position: 'relative' }"
          >
          </v-img>
        </router-link>
        <v-divider color="primary"></v-divider>
      </v-list-item>
      <v-list>
        <v-list-group v-model="currentNav">
          <v-list-item
            class="nav-link"
            v-for="(item, i) in navItems"
            :key="i + 'dev'"
            :to="{ name: item.name }"
            :aria-label="item.meta.title"
          >
            <v-list-item-title
              class="text-left"
            >
              {{ item.meta.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar scroll-behavior="elevate" flat height="100" class="navbar">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-sm-and-up"
        aria-label="Open Menu"
      >
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <router-link to="/" tag="span">
          <v-img
            alt="plustime-logo"
            class="mx-auto"
            max-height="60"
            max-width="60"
            :src="WhiteLogoNoText"
            :to="'/home'"
            :style="{ position: 'relative' }"
          >
          </v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs">
        <v-btn
          variant="flat"
          v-for="(item, i) in navItems"
          :key="i"
          :to="{ name: item.name }"
          class="nav-link mx-2"
        >
          {{ item.meta.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        v-if="mdAndUp"
        class="accessory mr-4"
        color="primary"
        variant="outlined"
        height="40"
        :to="{ name: 'Contact' }"
      >
        <span class="text-white"> Contact Us </span>
      </v-btn>
      <v-btn
        v-if="!mdAndUp"
        color="primary"
        icon="mdi-phone"
        height="40"
        :to="{ name: 'Contact' }"
        aria-label="Contact Us"
      ></v-btn>
    </v-app-bar>
  </div>
</template>

<style scoped lang="scss">
@import '../styles/variables';

.accessory {
  width: 250px;
}

.navbar {
  background: linear-gradient(
    to left,
    lighten(color('background'), 2%) 20%,
    color('background') 100%
  );
}

.nav-link {
  font-weight: 700;
  color: white;
  background: linear-gradient(
    to right,
    color('lighttext'),
    lighten(color('lighttext'), 50%)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  transition: all 0.4s ease-in-out;

  &:hover,
  &.v-btn--active {
    background: linear-gradient(
      to left,
      color('primary'),
      lighten(color('primary'), 30%)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: movingGradient 0.8s linear infinite;
  }

  .v-btn__overlay {
    background: none !important;
  }
}

@keyframes movingGradient {
  0%,
  100% {
    background: linear-gradient(
      to left,
      color('primary'),
      lighten(color('primary'), 10%)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  25%,
  75% {
    background: linear-gradient(
      to right,
      lighten(color('primary'), 10%),
      lighten(color('primary'), 30%)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  50% {
    background: linear-gradient(
      to right,
      lighten(color('primary'), 30%),
      lighten(color('primary'), 50%)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
