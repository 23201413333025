<script setup lang="ts">
import './styles/globalStyles.scss';
import FooterComponent from './components/FooterComponent.vue';
import NavComponent from './components/NavComponent.vue';
import { usePlatformStore } from './plugins/pinia/stores/platform';
import { storeToRefs } from 'pinia';

const store = usePlatformStore();
const { loading } = storeToRefs(store);

</script>
<template>
  <VApp id="plustime-guest" class="text-center background">
    <VMain>
      <NavComponent app />
      <RouterView app v-show="!loading" />
      <Transition name="bounce" appear>
        <VContainer app v-show="loading" class="h-100">
          <VRow class="h-100" justify="center" align="center">
            <VCol cols="12">
              <VProgressCircular
                indeterminate
                color="primary"
                :size="50"
                :width="8"
              ></VProgressCircular>
              <p class="mt-2">Excellence is loading...</p>
            </VCol>
          </VRow>
        </VContainer>
      </Transition>
      <FooterComponent />
    </VMain>
  </VApp>
</template>

<style scoped lang="scss">
@import './styles/variables';
</style>
