import type { MetaTag } from '../types/meta';

export function updateMeta(metaTags: MetaTag[]): void {
  for (const meta of metaTags) {
    const metaTag = document.querySelector(`meta[name="${meta.name}"]`);
    if (metaTag) {
      metaTag.setAttribute('content', meta.content);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', meta.name);
      newMetaTag.setAttribute('content', meta.content);
      document.head.appendChild(newMetaTag);
    }
  }
}
