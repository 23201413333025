import { createRouter, createWebHashHistory } from 'vue-router';
import { usePlatformStore } from '../pinia/stores/platform';
import { updateMeta } from '../../composables/metaUpdater';

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "home" */ /* webpackPrefetch: true */ '../../pages/HomePage.vue'
      ),
    name: 'Home',
    meta: {
      title: 'Home',
      showTopNav: true,
      showBottomNav: false,
      metaTags: [
        {
          name: 'title',
          content: 'IT Solutions and Website Designers - PlusTime IT Services'
        },
        {
          name: 'description',
          content:
            'Explore cutting-edge IT solutions with PlusTime IT, your trusted technology partner. From innovative software to seamless network solutions.'
        },
        {
          name: 'keywords',
          content:
            'software development, web development, mobile app development, web design, mobile app design, web development company, mobile app development company, web design company, mobile app design company, software development company'
        }
      ]
    }
  },
  {
    path: '/services',
    component: () =>
      import(
        /* webpackChunkName: "services" */ /* webpackPrefetch: true */ '../../pages/ServicePage.vue'
      ),
    name: 'Services',
    meta: {
      title: 'Services',
      showTopNav: true,
      showBottomNav: false,
      metaTags: [
        {
          name: 'title',
          content: 'Comprehensive IT Solutions and Services - PlusTime IT'
        },
        {
          name: 'description',
          content:
            "Discover PlusTime IT's comprehensive suite of services, spanning website development, app development, UI/UX design, SEO, digital marketing, content writing, Interim CTO, custom platforms, debugging, testing, security audits, analytical data, AWS, and networking."
        },
        {
          name: 'keywords',
          content:
            'IT solutions, website development, app development, UI/UX design, SEO, digital marketing, content writing, Interim CTO, custom platforms, debugging, testing, security audits, analytical data, AWS services, networking services, technology solutions, PlusTime IT services'
        }
      ]
    }
  },
  {
    path: '/services/:service',
    component: () =>
      import(
        /* webpackChunkName: "services" */ '../../pages/ServiceDisplayPage.vue'
      ),
    name: 'Service Display',
    meta: {
      title: 'Service Display',
      showTopNav: false,
      showBottomNav: false
    }
  },
  {
    path: '/open-source-projects',
    component: () =>
      import(
        /* webpackChunkName: "op-projects" */ '../../pages/OpenSourceProjectsPage.vue'
      ),
    name: 'Open Source Projects',
    meta: {
      title: 'Open Source Projects',
      showTopNav: true,
      showBottomNav: false,
      metaTags: [
        {
          name: 'title',
          content:
            'Explore PlusTime IT Open Source Projects - Collaborative Innovation in Technology'
        },
        {
          name: 'description',
          content:
            "Discover PlusTime IT's commitment to fostering collaboration, innovation, and transparency through our Open Source Projects. Explore Laravel EasyForms, Laravel Vue EasyForms, and Histoire Vuetify Design System, showcasing our dedication to the global open-source community."
        },
        {
          name: 'keywords',
          content:
            'open source projects, collaboration, innovation, transparency, Laravel EasyForms, Laravel Vue EasyForms, Histoire Vuetify Design System, PlusTime IT open source, technology, global community, open-source community'
        }
      ]
    }
  },
  {
    path: '/contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../../pages/ContactPage.vue'),
    name: 'Contact',
    meta: {
      title: 'Contact',
      showTopNav: false,
      showBottomNav: false
    }
  },
  {
    path: '/book-consultation',
    component: () =>
      import(
        /* webpackChunkName: "contact" */ '../../pages/ConsultationPage.vue'
      ),
    name: 'Consultation',
    meta: {
      title: 'Book Consultation',
      showTopNav: false,
      showBottomNav: true
    }
  },
  {
    path: '/terms-and-conditions',
    component: () =>
      import(/* webpackChunkName: "terms" */ '../../pages/TermsPage.vue'),
    name: 'Terms',
    meta: {
      title: 'Terms and Conditions',
      showTopNav: false,
      showBottomNav: true
    }
  },
  {
    path: '/privacy-policy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../../pages/PrivacyPage.vue'),
    name: 'Privacy',
    meta: {
      title: 'Privacy Policy',
      showTopNav: false,
      showBottomNav: true
    }
  }
];

function gtag(...args: any[]) {
  window.dataLayer.push(args);
}

gtag('js', new Date());
gtag('config', 'G-YVGQXBQNZV');

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({ top: 0 })
});

router.beforeEach((to, from) => {
  const { isLoading } = usePlatformStore();
  isLoading(true);
  if (to.meta.metaTags !== undefined && (to.meta.metaTags?.length ?? 0) > 0) {
    console.log('Meta found, updating', to.meta.metaTags);
    updateMeta(to.meta.metaTags);
  }

  gtag('page_view', {
    page_referrer: from.fullPath,
    page_location: to.fullPath
  });
});

export default router;
