declare global {
  interface Window {
    dataLayer: any;
  }
}

import { createApp } from 'vue';
import { vuetify, router, store } from './plugins';
import FormLoaderPlugin from 'laravel-vue-easyforms';
import App from './App.vue';
import axios from './plugins/axios';
import '@mdi/font/css/materialdesignicons.css';

createApp(App)
  .use(FormLoaderPlugin, {
    backend_domain: import.meta.env.VITE_APP_BACKEND_APP_URL,
    axios_prefix: '/internal/v1.0.0',
    csrf_endpoint: '/csrf-cookie',
    uses_vue_router: true,
    required_tags_only: false,
    tags_on_placeholder: true,
    tags_on_labels: false,
    axios: axios
  })
  .use(store)
  .use(vuetify)
  .use(router)
  .mount('#app');

window.dataLayer = window.dataLayer || [];
